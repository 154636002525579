import React from "react";

const Diamond = ({
    fill = "#FFFFFF",
    size = "60px",
    text = ""
}) => {
    switch (size) {
        case 24:
            return (
                <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                    <g id="diamond24" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <polygon id="color" fill={fill} points="11.9230408 2 23.1307161 21 1.13071613 21"></polygon>
                        <path d="M20.6600846,22.8222849 C22.6798307,22.8222849 24,21.2990127 24,19.471086 C24,18.9181946 23.8533145,18.3427362 23.5599436,17.8236953 L14.8829337,2.68124118 C14.2397743,1.56417489 13.1339915,1 12.0056417,1 C10.877292,1 9.74894217,1.57545839 9.11706629,2.68124118 L0.440056417,17.8349788 C0.146685472,18.3540197 0,18.9181946 0,19.471086 C0,21.2990127 1.32016925,22.8222849 3.33991537,22.8222849 L20.6600846,22.8222849 Z M20.2877292,20.3624824 L3.7122708,20.3624824 C3.08039492,20.3624824 2.6629055,19.8434415 2.6629055,19.2679831 C2.6629055,19.1100141 2.6854725,18.9069111 2.77574048,18.7263752 L11.0916784,4.14809591 C11.2834979,3.80959097 11.6558533,3.651622 12.0056417,3.651622 C12.3554302,3.651622 12.7052186,3.80959097 12.9083216,4.15937941 L21.212976,18.7376587 C21.303244,18.9181946 21.348378,19.1100141 21.348378,19.2679831 C21.348378,19.8434415 20.9196051,20.3624824 20.2877292,20.3624824 Z" id="diamond" fill="#000000" fillRule="nonzero"></path>
                        <text id="index" fontSize="1rem" fontWeight="bold" fontFamily="Roboto" fill="#000000">
                            <tspan x="7.274" y="18">{text}</tspan>
                        </text>
                    </g>
                </svg>
            )
        case 40:
            return (
                <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                    <g id="diamond40" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <circle id="halo1" fillOpacity="0.35" fill="#0079FF" cx="20" cy="20" r="20"></circle>
                        <polygon id="color" fill={fill} points="19.9230408 10 31.1307161 29 9.13071613 29"></polygon>
                        <path d="M28.6600846,30.8222849 C30.6798307,30.8222849 32,29.2990127 32,27.471086 C32,26.9181946 31.8533145,26.3427362 31.5599436,25.8236953 L22.8829337,10.6812412 C22.2397743,9.56417489 21.1339915,9 20.0056417,9 C18.877292,9 17.7489422,9.57545839 17.1170663,10.6812412 L8.44005642,25.8349788 C8.14668547,26.3540197 8,26.9181946 8,27.471086 C8,29.2990127 9.32016925,30.8222849 11.3399154,30.8222849 L28.6600846,30.8222849 Z M28.2877292,28.3624824 L11.7122708,28.3624824 C11.0803949,28.3624824 10.6629055,27.8434415 10.6629055,27.2679831 C10.6629055,27.1100141 10.6854725,26.9069111 10.7757405,26.7263752 L19.0916784,12.1480959 C19.2834979,11.809591 19.6558533,11.651622 20.0056417,11.651622 C20.3554302,11.651622 20.7052186,11.809591 20.9083216,12.1593794 L29.212976,26.7376587 C29.303244,26.9181946 29.348378,27.1100141 29.348378,27.2679831 C29.348378,27.8434415 28.9196051,28.3624824 28.2877292,28.3624824 Z" id="diamond" fill="#000000" fillRule="nonzero"></path>
                        <text id="index" fontSize="1rem" fontWeight="bold" fontFamily="Roboto" fill="#000000">
                            <tspan x="15" y="26">{text}</tspan>
                        </text>
                    </g>
                </svg>
            )
        case 50:
            return (
                <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                    <g id="diamond50" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <circle id="halo1-copy" fillOpacity="0.35" fill="#0079FF" cx="25" cy="25" r="25"></circle>
                        <polygon id="color" fill={fill} points="24.9230408 15 36.1307161 34 14.1307161 34"></polygon>
                        <path d="M33.6600846,35.8222849 C35.6798307,35.8222849 37,34.2990127 37,32.471086 C37,31.9181946 36.8533145,31.3427362 36.5599436,30.8236953 L27.8829337,15.6812412 C27.2397743,14.5641749 26.1339915,14 25.0056417,14 C23.877292,14 22.7489422,14.5754584 22.1170663,15.6812412 L13.4400564,30.8349788 C13.1466855,31.3540197 13,31.9181946 13,32.471086 C13,34.2990127 14.3201693,35.8222849 16.3399154,35.8222849 L33.6600846,35.8222849 Z M33.2877292,33.3624824 L16.7122708,33.3624824 C16.0803949,33.3624824 15.6629055,32.8434415 15.6629055,32.2679831 C15.6629055,32.1100141 15.6854725,31.9069111 15.7757405,31.7263752 L24.0916784,17.1480959 C24.2834979,16.809591 24.6558533,16.651622 25.0056417,16.651622 C25.3554302,16.651622 25.7052186,16.809591 25.9083216,17.1593794 L34.212976,31.7376587 C34.303244,31.9181946 34.348378,32.1100141 34.348378,32.2679831 C34.348378,32.8434415 33.9196051,33.3624824 33.2877292,33.3624824 Z" id="diamond" fill="#000000" fillRule="nonzero"></path>
                        <text id="index" fontSize="1rem" fontWeight="bold" fontFamily="Roboto" fill="#000000">
                            <tspan x="20" y="31">{text}</tspan>
                        </text>
                    </g>
                </svg>
            )
        default:
            return (
                <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                    <g id="diamond60" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <circle id="halo1-copy-2" fillOpacity="0.35" fill="#0079FF" cx="30" cy="30" r="30"></circle>
                        <polygon id="color" fill={fill} points="29.9230408 20 41.1307161 39 19.1307161 39"></polygon>
                        <path d="M38.6600846,40.8222849 C40.6798307,40.8222849 42,39.2990127 42,37.471086 C42,36.9181946 41.8533145,36.3427362 41.5599436,35.8236953 L32.8829337,20.6812412 C32.2397743,19.5641749 31.1339915,19 30.0056417,19 C28.877292,19 27.7489422,19.5754584 27.1170663,20.6812412 L18.4400564,35.8349788 C18.1466855,36.3540197 18,36.9181946 18,37.471086 C18,39.2990127 19.3201693,40.8222849 21.3399154,40.8222849 L38.6600846,40.8222849 Z M38.2877292,38.3624824 L21.7122708,38.3624824 C21.0803949,38.3624824 20.6629055,37.8434415 20.6629055,37.2679831 C20.6629055,37.1100141 20.6854725,36.9069111 20.7757405,36.7263752 L29.0916784,22.1480959 C29.2834979,21.809591 29.6558533,21.651622 30.0056417,21.651622 C30.3554302,21.651622 30.7052186,21.809591 30.9083216,22.1593794 L39.212976,36.7376587 C39.303244,36.9181946 39.348378,37.1100141 39.348378,37.2679831 C39.348378,37.8434415 38.9196051,38.3624824 38.2877292,38.3624824 Z" id="diamond" fill="#000000" fillRule="nonzero"></path>
                        <text id="index" fontSize="1rem" fontWeight="bold" fontFamily="Roboto" fill="#000000">
                            <tspan x="25" y="39">{text}</tspan>
                        </text>
                    </g>
                </svg>
            )
    }
};

export default Diamond;